export default {
    tileLayerUrl: {
        type: String,
        default: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    },
    zoom: {
        type: Number,
        default: 7
    },
    center: {
        type: [Array, Object],
        default: function() {
            return {
                lat: 46.15039,
                lng: 14.91421
            }
        }
    },
    mapOptions: {
        type: Object,
        default: function() {
            return {
                zoomSnap: 0.5
            }
        }
    },
    maxZoom: {
        type: Number,
        default: 18
    },
    minZoom: {
        type: Number,
    },
    fullscreen: {
        type: Boolean,
        default: false
    },
    height: {
        type: Number,
        default: 500
    },
    legend: Object,
    markers: {
        type: Array,
        default: function() {
            return []
        }
    },
    polygons: {
        type: Array,
        default: function() {
            return []
        }
    },
    circles: {
        type: Array,
        default: function() {
            return []
        }
    },
    forceFitBoundsToMarkers: {
        type: Boolean,
        default: false
    },
    fitBoundsPadding: {
        type: Number,
        default: 50
    },
    mouseover_style: {
        type: Object,
        default: function() {
            return {
                color: "#f6ff00",
                fillColor: "#f6ff00",
                fillOpacity: 0.2,
            }
        }
    },
    polygonPopupEnabled: {
        type: Boolean,
        default: true,
    },
}