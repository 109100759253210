<template>
    <div>
        <KSimpleLeafletMap
            v-bind="$props"
            ref="k_simple_leafletmap"
            @click="$emit('click', $event)"
        >
        </KSimpleLeafletMap>
    </div>
</template>

<script>

// import L from 'leaflet';

// import { LMap, LControlLayers, LTileLayer } from 'vue2-leaflet';
import KSimpleLeafletMap from './KSimpleLeafletMap.vue'

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { transformLayerToJson } from '../utils/geometry.js';

export default {
    name: 'KDrawLeafletMap',
    components: {
        KSimpleLeafletMap,
    },
    props: {
        url: {
            type: String,
        },
        zoom: {
            type: Number,
        },
        center: {
            type: Array,
        },
        mapOptions: {
            type: Object,
        },
        fullscreen: {
            type: Boolean,
        },
        geoms: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },

    data: function() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {
        const app = this;
        this.$nextTick(() => {
            let map = this.$refs.k_simple_leafletmap.$refs.map.mapObject;

            const customTranslation = {
                "tooltips": {
                    "placeMarker": this.$t("leaflet_map:tooltips:place_marker"),
                    "firstVertex": this.$t("leaflet_map:tooltips:first_vertex"),
                    "continueLine": this.$t("leaflet_map:tooltips:continue_line"),
                    "finishLine": this.$t("leaflet_map:tooltips:finish_line"),
                    "finishPoly": this.$t("leaflet_map:tooltips:finish_poly"),
                    "finishRect": this.$t("leaflet_map:tooltips:finish_rect"),
                    "startCircle": this.$t("leaflet_map:tooltips:start_circle"),
                    "finishCircle": this.$t("leaflet_map:tooltips:finish_circle"),
                    "placeCircleMarker": this.$t("leaflet_map:tooltips:place_circle_marker")
                },
                "actions": {
                    "finish": this.$t("leaflet_map:actions:finish"),
                    "cancel":  this.$t("leaflet_map:actions:cancel"),
                    "removeLastVertex":  this.$t("leaflet_map:actions:remove_last_vertex")
                },
                "buttonTitles": {
                    "drawMarkerButton": this.$t("leaflet_map:buttons:draw_marker"),
                    "drawPolyButton": this.$t("leaflet_map:buttons:draw_poly"),
                    "drawLineButton": this.$t("leaflet_map:buttons:draw_line"),
                    "drawCircleButton":this.$t("leaflet_map:buttons:draw_circle"),
                    "drawRectButton": this.$t("leaflet_map:buttons:draw_rect"),
                    "editButton": this.$t("leaflet_map:buttons:edit"),
                    "dragButton": this.$t("leaflet_map:buttons:drag"),
                    "cutButton": this.$t("leaflet_map:buttons:cut"),
                    "deleteButton": this.$t("leaflet_map:buttons:delete"),
                    "drawCircleMarkerButton": this.$t("leaflet_map:buttons:draw_circle"),
                    "snappingButton": this.$t("leaflet_map:buttons:snap"),
                    "pinningButton": this.$t("leaflet_map:buttons:pin"),
                }
            };
            map.pm.setLang('arvio-slo', customTranslation, 'en');

            map.pm.addControls({
                position: "topleft",
                drawMarker: false,
                drawCircleMarker: false,
                drawPolyline: false,
                cutPolygon: false,
            });

            map.on("pm:create", function(e){
                app.$set(app.geoms, e.layer._leaflet_id, transformLayerToJson(e.layer));
                e.layer.on("pm:edit", function(){
                    app.$set(app.geoms, e.layer._leaflet_id, transformLayerToJson(e.layer));
                });
            });

            map.on("pm:remove",function(e){
                app.$delete(app.geoms, e.layer._leaflet_id);
            });
        });
    },  
}
</script>
